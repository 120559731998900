import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';

const UploadingVideoProgress = ({ videoCompresorField }) => {
	const imageUploadCancel = () => {
		window.location.reload();
	};
	return (
		<section className="youtube-down-sec tools-sec converting-video-sec">
			<div className="container">
				<div className="tools-heading">
					<h1 className="sub-heading mx-auto">Uploading video ...</h1>
				</div>
				<div className="youtube-down-con">
					<form>
						<div className="percentage-text">
							{' '}
							{!!videoCompresorField.uploadProgress
								? videoCompresorField.uploadProgress + '%'
								: '1%'}
						</div>

						<ProgressBar
							now={videoCompresorField.uploadProgress}
						/>
						<p>Please wait while your video is uploaded.</p>

						<div>
							<p className="alert alert-info">Please note that generating your shorts may take <b>approximately 5-10 minutes</b>. Transcribing the video and exporting the final clip are time-consuming tasks to ensure the highest quality output.</p>
						</div>

						<div className="secondary-btn">
							<button
								type="button"
								title="Cancel"
								onClick={() => imageUploadCancel()}
							>
								Cancel
							</button>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};
export default UploadingVideoProgress;
