import { React, useState } from 'react';
import ReactGA from 'react-ga4';
import amplitude from 'amplitude-js';
import axios from 'axios';
const SsemblePricingCard = ({ ShortsMakerField, plans_level }) => {


	const [activeTab, setActiveTab] = useState(0);

	const handleCheckout = (chosenBillingType) => {

		if (
			ShortsMakerField?.UserStatusData?.isLoggedIn &&
			!ShortsMakerField?.UserStatusData?.isProUser
		) {
			handlePaymentSessions(
				ShortsMakerField?.shortcodeSettings1?.auth_settings_options?.api_base_url_0,
				chosenBillingType
			);
		} else if (!ShortsMakerField?.UserStatusData?.isLoggedIn) {
			const isAmplitudeEnabled =
				ShortsMakerField?.ShortsMakerField?.shortcodeSettings1
					?.enable_tracking;
			const amplitude_tracking_key =
				ShortsMakerField?.ShortsMakerField?.shortcodeSettings1
					?.amplitude_tracking_key;

			ReactGA.event({
				category: 'Upgrade to Pro Button Clicked',
				action: 'Upgrade to Pro Button Clicked',
				label: 'Upgrade to Pro Button Clicked',
			});

			if (isAmplitudeEnabled == '1') {
				var eventProperties1 = {
					page_title: document.title,
					page_url: window.location.href,
					'Clicked From': 'Pricing Table',
				};
				amplitude
					.getInstance()
					.logEvent(
						'Upgrade to Pro Button Clicked',
						eventProperties1
					);
			}

			window.location.href =
				ShortsMakerField?.shortcodeSettings1?.auth_settings_options?.signup_url_2 +
				'?external=true&redirectTo=' +
				window.location.href;
		}
	};

	function handlePaymentSessions(baseUrl, chosenBillingType) {
		// Upgrade to Pro Button Clicked

		const isAmplitudeEnabled =
			ShortsMakerField?.ShortsMakerField?.shortcodeSettings1
				?.enable_tracking;
		const amplitude_tracking_key =
			ShortsMakerField?.ShortsMakerField?.shortcodeSettings1
				?.amplitude_tracking_key;

		ReactGA.event({
			category: 'Upgrade to Pro Button Clicked',
			action: 'Upgrade to Pro Button Clicked',
			label: 'Upgrade to Pro Button Clicked',
		});

		if (isAmplitudeEnabled == '1') {
			var eventProperties1 = {
				page_title: document.title,
				page_url: window.location.href,
				'Clicked From': 'Pricing Table',
			};
			amplitude
				.getInstance()
				.logEvent('Upgrade to Pro Button Clicked', eventProperties1);
		}
		const apiBaseUrl = `${baseUrl}payments/session`;
		var config = {
			method: 'POST',
			url: `${apiBaseUrl}`,
			withCredentials: true,
			data: JSON.stringify({ productId: chosenBillingType }),
			headers: {
				'request-page': window.location.href,
				'cancel-page': window.location.href,
				'success-page': ShortsMakerField?.shortcodeSettings1?.site_url + '/payment-success',
				'Content-Type': 'application/json',
			},
		};

		axios(config)
			.then((response) => {
				if (response?.status === 200) {
					window.location.href = response?.data?.checkoutUrl;
				}
			})
			.catch((error) => {

			});
	}

	return (
		<>


			{plans_level?.level_plan_type.map(
				(level_plan, index) => (
					<div
						className={`pricing-tab-card ${level_plan?.billing_cycle_class}`}
					>
						<div className="upperWrapper">
							<div className="pricing-heading">
								<div className="pricing-icon">
									{level_plan?.plan_icon != '' && (
										<img
											src={
												level_plan.plan_icon
													.sizes.thumbnail
											}
											alt={
												level_plan.plan_icon.alt
											}
										/>
									)}
								</div>
								<h2>{level_plan?.plan_type_label}</h2>
							</div>

							<div className="pricing-content">
								<div class="ssemblePricingWrapperInner">
									<div className="pricing-number">
										<span
											className={`ssregular_price${level_plan.plan_sales_price !=
												''
												? ' ss_strike'
												: ''
												}`}
										>
											{
												level_plan.plan_regular_price
											}
										</span>
										{level_plan.plan_sales_price !=
											'' && (
												<span className="ss_sale_price">
													{
														level_plan.plan_sales_price
													}
												</span>
											)}
									</div>


									{level_plan.additonal_text != '' && (
										<div className="per-editor-text">
											<span>
												{
													level_plan.additonal_text
												}
											</span>
										</div>
									)}

								</div>

								{level_plan.plan_button != '' && (
									<div className="pricing-btn">
										{(() => {
											if (
												!ShortsMakerField
													?.ShortsMakerField
													?.UserStatusData
													?.isLoggedIn ||
												!ShortsMakerField
													?.ShortsMakerField
													?.UserStatusData
													?.isProUser
											)
												return (
													<a
														className="button"
														onClick={() =>
															handleCheckout(
																level_plan.plan_product_name
															)
														}
													>
														{
															level_plan
																.plan_button
																.title
														}
													</a>
												);
										})()}
									</div>
								)}

							</div>
						</div>
						<div className="lowerWrapper">
							<div className="pricing-list">
								<ul className="pricing_features">
									{level_plan.features_in_the_plan.map((feature_item, index) => {
										const parts = feature_item.feature_label.split(/(Shorts Maker|Shorts Narrator)/);

										return (
											<li key={index}>
												{parts.map((part, partIndex) => {
													if (part === "Shorts Maker") {
														return <a key={partIndex} href="/youtube-shorts-maker">{part}</a>;
													} else if (part === "Shorts Narrator") {
														return <a key={partIndex} href="/shorts-narrator">{part}</a>;
													} else {
														return <span key={partIndex}>{part}</span>;
													}
												})}
											</li>
										);
									})}
								</ul>

							</div>
						</div>
					</div>
				)
			)}

		</>
	);
};
export default SsemblePricingCard;
