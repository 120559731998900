import React, { useEffect } from 'react';

const frontendURL = process.env.REACT_APP_URL;

const AuthProcessing = () => {
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        
        const tokens = {
            error: urlParams.get('error'),
            token: urlParams.get('token'),
            insta_token: urlParams.get('insta_token'),
            insta_auth_token: urlParams.get('insta_auth_token'),
            tiktok_token: urlParams.get('tiktok_token'),
            tiktok_auth_token: urlParams.get('tiktok_auth_token'),
        };

        let postMessageData = {};




        if (tokens.error) {
            postMessageData = {
                type: 'error',
                message: 'Permission denied.'
            };
        } else if (tokens.token) {
            postMessageData = {
                googleToken: tokens.token,
                type: 'googleSuccess'
            };
        } else if (tokens.insta_token) {
            postMessageData = {
                insta_token: tokens.insta_token,
                insta_auth_token: tokens.insta_auth_token,
                type: 'instaSuccess'
            };
        } else if (tokens.tiktok_token) {
            postMessageData = {
                tiktok_token: tokens.tiktok_token,
                tiktok_auth_token: tokens.tiktok_auth_token,
                type: 'tiktokSuccess'
            };
        }

        if (window.opener && !window.opener.closed) {
            const targetURL = `${frontendURL.endsWith('/') ? frontendURL : `${frontendURL}/`}upload`;
            window.opener.postMessage(postMessageData, targetURL);
            window.close();
        } else {
            console.error('No opener window found or opener window is closed.');
        }
    }, []);

    return <p>Processing...</p>;
}

export default AuthProcessing;
