import openSocket from 'socket.io-client';

const videosocket = openSocket(
	'https://video-compressor-dot-ssemble-plugins-backend.uc.r.appspot.com',
	{ withCredentials: false, transports: [ 'websocket' ] }
);

function subscribeToTimer( id, callback ) {
	videosocket
		.off( id )
		.on( id, ( progress_state, progress ) =>
			callback( null, progress_state, progress )
		);
	//socket.emit('subscribeToTimer', 0);
}

function sendForm( jsonObject ) {
	videosocket.emit( 'client-event', JSON.stringify( jsonObject ) );
}

function validateURL( jsonObject ) {
	videosocket.emit( 'validation_event', JSON.stringify( jsonObject ) );
}

export { subscribeToTimer, videosocket };
export { sendForm };
export { validateURL };
