import io from 'socket.io-client';

const videoSocket = io(
	'https://youtube-importer-shorts-dot-ssemble-plugins-backend.uc.r.appspot.com/',
	{ withCredentials: false, transports: [ 'websocket', 'polling' ] }
);

const validateURL = (url) => {
  videoSocket.emit('validation_event', JSON.stringify(url));
};

const subscribeToEvent = (id, callback) => {
	videoSocket.on( id, (...args) =>
		callback(...args)
	);
}

export { subscribeToEvent, validateURL, videoSocket };

