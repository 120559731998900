import axios from 'axios';
const endPoint = 'https://dev-plugin-shortsmaker-api.ssemble.com';
const creditsEndPoint = 'https://api-dev.ssemble.com';

// const endPoint = 'https://plugin-shortsmaker-api.ssemble.com';
// const creditsEndPoint = 'https://api.ssemble.com';


// console.log("creditsEndPoint", creditsEndPoint);
const getShorts = async (data) => {
  // console.log('getShorts', data);
  try {
    const response = await axios.post(endPoint + '/initialize-request', data);
    // console.log('initialize-request response', response);
    const requestId = response.data.insertedId;
    // console.log('insertedId', requestId);
    return requestId;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}

const getRequestStatus = async (requestId) => {

  try {
    const response = await axios.get(endPoint + `/request-status?requestId=${requestId}`);
    return response.data
  } catch (error) {
    console.error('Error fetching video processing stage:', error, requestId);
  }
};


const assignRequest = async (data) => {
  // console.log('assignRequest', data);

  try {
    const response = await axios.post(endPoint + '/assign-request', data);
    // console.log('assign-request', response);
    return response;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}


const getRequest = async (requestId) => {

  try {
    const response = await axios.get(endPoint + `/request?requestId=${requestId}`);
    return response.data
  } catch (error) {
    console.error('Error fetching video processing stage:', error, requestId);
  }
};

const getUserRequests = async (userId) => {
  // console.log('getUserRequests', userId)
  try {
    const response = await axios.get(endPoint + `/userRequests/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user requests:', error);
    return [];
  }
};

const deleteRequest = async (id) => {
  try {
    const response = await axios.delete(endPoint + `/requests/${id}`);
    // console.log('Video successfully deleted');
    return response
  } catch (error) {
    console.error('Failed to delete the video:', error);
  }
};

const getUserCredits = async (userId) => {
  // console.log("Userid:", userId);
  try {
    const response = await axios.get(creditsEndPoint + `/credits/shorts_maker/user/${userId}`);
    // console.log('getUserCredits response', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user credits:', error);
    return null;
  }
};


export {
  getShorts,
  getRequestStatus,
  assignRequest,
  getRequest,
  getUserRequests,
  deleteRequest,
  getUserCredits
};