import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { changeVideoCompressorField } from '../../Redux/Actions/ToolActions';
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FileUploader } from 'react-drag-drop-files';
import {
	validateURL,
} from '../../utilies/api-vc';
import uuid from 'react-uuid';
import UploadingVideoProgress from './UploadingVideoProgress';
const fileTypes = ['mov', 'mp4', 'webm'];
// "asf", "flac", "flv", "gif", "m4v", "mkv", "webm", "wmv"
const VideoDragDropUploader = ({ shortcodeSettings, onSuccessUpload }) => {
	const dispatch = useDispatch();
	const VideoStateReducer = useSelector(
		(state) => state.VideoCompresserReducer
	);
	const [errorMessage, setErrorMessage] = useState('');
	const [showdrag, setShowDrag] = useState(false);
	const [showImageMessage, setShowImageMessage] = useState('');
	const { videoCompresorField } = VideoStateReducer;
	const [showImageError, setShowImageError] = useState(false);
	const [openModel, setOpenModel] = useState(false);


	const [videoSize, setVideoSize] = useState(null);
	const [videoResolution, setVideoResolution] = useState(null);
	const [videoWidth, setVideoWidth] = useState(null);
	const [videoHeight, setVideoHeight] = useState(null);
	const [videoLength, setVideoLength] = useState(null);
	const [fileName, setFileName] = useState(null);
	const [fileNameWithoutExtension, setFileNameWithoutExtension] = useState(null);
	const [error, setError] = useState(null);

	useEffect(() => {
		dispatch(
			changeVideoCompressorField({
				shortcodeSettings1: shortcodeSettings,
			})
		);
	}, []);

	useEffect(() => {
		$('.paste-url-sec').click(function (e) {
			e.preventDefault();
			e.stopPropagation();
			setOpenModel(true);
			return false;
		});
	}, [videoCompresorField.step]);

	const handleChangeModelUrl = (e) => {
		dispatch(
			changeVideoCompressorField({ VideoUrlInput: e.target.value })
		);
		if (e.target.value) {
			var res = e.target.value.match(
				/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
			);
			if (res == null) {
				setErrorMessage('Invalid URL!');
			} else {
				setErrorMessage('');
			}
		} else {
			setErrorMessage('Please enter a URL!');
		}
	};
	const handleModelCancel = () => {
		setOpenModel(false);
	};

	const convertImageLink = () => {
		setErrorMessage('');
		if (!!videoCompresorField.VideoUrlInput) {
			let handleUploadChange = {
				uploadVideoLoader: true,
			};
			dispatch(changeVideoCompressorField(handleUploadChange));
			var res = videoCompresorField.VideoUrlInput.match(
				/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
			);
			if (res == null) {
				setErrorMessage('Invalid URL!');
			} else {
				validateURL(videoCompresorField.VideoUrlInput);
			}
		} else {
			setErrorMessage('Please enter a URL!');
		}
	};

	const uploadChunk = async (
		chunk,
		index,
		totalChunks,
		unique_id,
		fileName
	) => {
		const formData = new FormData();
		formData.append('file', chunk);
		formData.append('index', index);
		formData.append('totalChunks', totalChunks);
		formData.append('fileName', fileName);
		formData.append('unique_id', unique_id);
		formData.append('userEmail', 'deepakanand767@gmail.com');
		await axios
			.post(
				'https://dev-plugin-shortsmaker-api.ssemble.com/upload-file',
				formData
			)
			.then((response) => {

				console.log("response---------", response);

				if (
					response.status == 200 &&
					response.data &&
					response.data.videoFileURL && response.data.thumbnailFileURL

				) {
					console.log("Here after chunk....");
					// const { data } = response.data;
					let isError = Boolean(true);
					if (!isError) {
						const errorUrl = {
							validUrlInput: true,
							uploadVideoLoader: false,
							message: 'Some error occurred while uploading',
						};
						dispatch(changeVideoCompressorField(errorUrl));
					} else {
						const errorUrl = {
							validUrlInput: true,
							uploadVideoLoader: false,
							uploadFileData: response,
							videoSize: videoSize,
							videoResolution: videoResolution,
							videoWidth: videoWidth,
							videoHeight: videoHeight,
							videoLength: videoLength,
							fileName: fileName,
							videoTitle: fileName,
							fileNameWithoutExtension: fileNameWithoutExtension,
							message: "Uploaded....",
							step: 3,
						};
						dispatch(changeVideoCompressorField(errorUrl));
						onSuccessUpload(errorUrl);
					}
				}
			})
			.catch((error) => {
				setShowImageError(true);
				setShowImageMessage('Error While Uploading file.');
				dispatch(changeVideoCompressorField({ step: 1 }));
			});
	};

	const handleChange = async (file) => {
		if (file.length < 2) {

			// check the resolutions and other metas before upload
			if (file) {
				const videoFile = file[0];
				const videoSizeInBytes = videoFile.size;
				const videoSizeInMB = (videoSizeInBytes / (1024 * 1024)).toFixed(2);
				console.log("videoSizeInMB::", videoSizeInMB);
				setVideoSize(videoSizeInMB);

				// Create a video element to load the video and retrieve its resolution
				const videoElement = document.createElement('video');
				const videoURL = URL.createObjectURL(videoFile);
				videoElement.src = videoURL;

				// Wait for the video metadata to be loaded
				await new Promise((resolve) => {
					videoElement.addEventListener('loadedmetadata', resolve);
				});

				console.log("videoElement data", videoElement);

				const videoWidth = videoElement.videoWidth;
				setVideoWidth(videoWidth);
				const videoHeight = videoElement.videoHeight;
				setVideoHeight(videoHeight);
				console.log("videoWidth -- videoHeight", videoWidth, videoHeight);

				setVideoResolution(`${videoWidth}x${videoHeight}`);

				const videoDuration = videoElement.duration;
				setVideoLength(videoDuration);

				if (videoDuration < 50) {
					setError('We do not support videos under 1 minute.');
					setShowImageError(true);
					setShowImageMessage('We do not support videos under 1 minute.');
					return;
				} else {
					setShowImageError(false);
				}


				// Clean up the video element and URL
				URL.revokeObjectURL(videoURL);

				// Get the file name with extension
				const name = videoFile.name;
				setFileName(name);

				// Get the file name without extension
				const nameWithoutExtension = name.substring(0, name.lastIndexOf('.'));
				setFileNameWithoutExtension(nameWithoutExtension);

			}


			setShowImageError(false);

			const handleUploadChange = {
				step: 2,
			};
			dispatch(changeVideoCompressorField(handleUploadChange));
			try {
				const CHUNK_SIZE = 5 * 1024 * 1024; // 1MB chunks
				const totalChunks = Math.ceil(file[0].size / CHUNK_SIZE);
				const unique_id = uuid();
				for (let i = 0; i < totalChunks; i++) {
					const start = i * CHUNK_SIZE;
					const end = Math.min(start + CHUNK_SIZE, file[0].size);
					const chunk = file[0].slice(start, end);
					await uploadChunk(
						chunk,
						i,
						totalChunks,
						unique_id,
						file[0].name
					);
					let percentComplete;
					if (totalChunks > 1)
						percentComplete = parseInt(
							(i / (totalChunks - 1)) * 100
						);
					else percentComplete = 100;
					const handleUploadChange = {
						uploadProgress: percentComplete,
					};
					dispatch(
						changeVideoCompressorField(handleUploadChange)
					);
				}
			} catch (ex) { }
		} else {
			setShowImageError(true);
			setShowImageMessage('Multiple file upload is not allowed');
			// alert(showImageError)
		}
	};
	const handleDrop = (dragging) => {
		// setShowImageError(false)
		setShowDrag(dragging);
	};
	const handleSizeError = () => {
		setShowImageError(true);
		setShowImageMessage("Videos over 100MB can't be uploaded.");
	};
	const handleTypeError = (err) => {
		setShowImageError(true);
		setShowImageMessage('This file type is not supported.');
	};

	return (
		<div className="bg_remover-wrapper ss_custom_dragDrop">
			{videoCompresorField.step == 1 || videoCompresorField.step == 3 ? (
				<FileUploader
					classes="drop_area drop_zone ss_drop_zone"
					handleChange={handleChange}
					maxSize={100}
					multiple={true}
					name="fileType"
					types={fileTypes}
					hoverTitle=""
					onSizeError={handleSizeError}
					onTypeError={handleTypeError}
					onDraggingStateChange={handleDrop}
					dropMessageStyle={{ "backgroundColor": '#3782F7', "border": '2px solid #3782F7' }}
				>
					<section
						className={
							showImageError
								? 'video-compressor-sec tools-sec during-drap-video  during-drap-video-error'
								: !!showdrag
									? 'video-compressor-sec tools-sec during-drap-video'
									: 'video-compressor-sec tools-sec '
						}
					>
						{!!showImageError && (
							<div className="video-not-support">
								{showImageMessage}
							</div>
						)}
						<div className="container">
							<div className="video-comp-con">
								<form>
									<div className="drag-drop-text">
										Choose a file (MP4, MOV, WEBM) or Drag it here
									</div>
								</form>
							</div>
						</div>
					</section>
				</FileUploader>
			) : videoCompresorField.step == 2 ? (
				<UploadingVideoProgress
					videoCompresorField={videoCompresorField} shortcodeSettings={shortcodeSettings}
				/>
			) : (
				''
			)}

			<Modal
				show={openModel}
				onHide={handleModelCancel}
				backdrop="static"
				keyboard={false}
				className="paste_url-modal"
			>
				<Modal.Body>
					<div
						title="Back"
						onClick={handleModelCancel}
						className="tools-back"
					>
						<h4>Back</h4>
					</div>
					<div className="enter-url-popup-con">
						<h4>Please enter a URL</h4>
						<div className="input-group">
							<input
								className={
									!!errorMessage ? 'error-message' : ''
								}
								type="url"
								id
								name
								placeholder="Enter URL"
								value={videoCompresorField.VideoUrlInput}
								onChange={handleChangeModelUrl}
							/>
						</div>
						{errorMessage ? (
							<span
								style={{ fontWeight: 'bold', color: 'red' }}
							>
								{errorMessage}
							</span>
						) : (
							''
						)}
						<div className="create-account-btn">
							<button
								className="vcompresoor_convertButtonIsClicked"
								type="button"
								disabled={
									!!videoCompresorField.uploadVideoLoader
										? true
										: false
								}
								onClick={() => convertImageLink()}
								title="Upload"
							>
								Upload
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};
export default VideoDragDropUploader;
