import React from 'react';
import axios from 'axios';
import ReactGA from 'react-ga4';
import amplitude from 'amplitude-js';
import SsemblePricingTabs from './ssemblePricingTabs';
const SsemblePricing = ({ ShortsMakerField }) => {


	function handleCheckout() {
		if (
			ShortsMakerField?.userStatus?.isLoggedIn &&
			!ShortsMakerField?.userStatus?.isProUser
		) {
			handlePaymentSessions(ShortsMakerField?.userStatus?.baseUrl);
		} else if (!ShortsMakerField?.userStatus?.isLoggedIn) {
			const isAmplitudeEnabled =
				ShortsMakerField?.shortcodeSettings1?.enable_tracking;
			const amplitude_tracking_key =
				ShortsMakerField?.shortcodeSettings1
					?.amplitude_tracking_key;

			ReactGA.event({
				category: 'Upgrade to Pro Button Clicked',
				action: 'Upgrade to Pro Button Clicked',
				label: 'Upgrade to Pro Button Clicked',
			});

			if (isAmplitudeEnabled == '1') {
				var eventProperties1 = {
					page_title: document.title,
					page_url: window.location.href,
					'Clicked From': 'Pricing Table',
				};
				amplitude
					.getInstance()
					.logEvent(
						'Upgrade to Pro Button Clicked',
						eventProperties1
					);
			}

			window.location.href =
				ShortsMakerField?.userStatus?.signUpUrl +
				'?external=true&redirectTo=' +
				window.location.href;
		}
	}

	function handlePaymentSessions(baseUrl) {
		// Upgrade to Pro Button Clicked

		const isAmplitudeEnabled =
			ShortsMakerField?.shortcodeSettings1?.enable_tracking;
		const amplitude_tracking_key =
			ShortsMakerField?.shortcodeSettings1
				?.amplitude_tracking_key;

		ReactGA.event({
			category: 'Upgrade to Pro Button Clicked',
			action: 'Upgrade to Pro Button Clicked',
			label: 'Upgrade to Pro Button Clicked',
		});

		if (isAmplitudeEnabled == '1') {
			var eventProperties1 = {
				page_title: document.title,
				page_url: window.location.href,
				'Clicked From': 'Pricing Table',
			};
			amplitude
				.getInstance()
				.logEvent('Upgrade to Pro Button Clicked', eventProperties1);
		}
		const apiBaseUrl = `${baseUrl}payments/session`;
		var config = {
			method: 'POST',
			url: `${apiBaseUrl}`,
			withCredentials: true,
			data: JSON.stringify({ productId: 'yt_downloader' }),
			headers: {
				'request-page': window.location.href,
				'Content-Type': 'application/json',
			},
		};

		axios(config)
			.then((response) => {
				if (response?.status === 200) {
					window.location.href = response?.data?.checkoutUrl;
				}
			})
			.catch((error) => {

			});
	}

	return (
		<>

			<div className="ssPricingTableWrapper">
				<div
					dangerouslySetInnerHTML={{
						__html: ShortsMakerField?.priceTableData
							?.pro_plans_heading,
					}}
				/>
				<SsemblePricingTabs ShortsMakerField={ShortsMakerField} />
			</div>

		</>
	);
};
export default SsemblePricing;
