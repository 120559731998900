import React, { useState, useEffect, useRef } from "react";
import "./TextEditor.css";
import BackIcon from "../../assets/back.svg";

const TextEditor = ({ request, shortId, onBack }) => {
  // const transcript = request.transcript || "";
  // const words = transcript.split(" ");
  // const [wordStates, setWordStates] = useState({}); // 각 단어의 상태 관리

  // const [selectedWord, setSelectedWord] = useState(null);
  // const [selectedWordPosition, setSelectedWordPosition] = useState({
  //   x: 0,
  //   y: 0,
  // }); // 상태 추가

  // const handleWordClick = (word, event) => {
  //   setSelectedWord(word);
  //   setSelectedWordIndex(index); // 선택된 단어의 인덱스 상태 추가

  //   const rect = event.target.getBoundingClientRect();
  //   setSelectedWordPosition({
  //     x: rect.left - rect.width / 2, // 단어의 가로 중앙
  //     y: rect.top,
  //   });
  // };

  // const editorRef = useRef(); // 에디터 ref
  // useEffect(() => {
  //   function handleClickOutside(event) {
  //     if (editorRef.current && !editorRef.current.contains(event.target)) {
  //       setSelectedWord(null);
  //     }
  //   }

  //   // 바깥 클릭 감지 이벤트 리스너 추가
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     // 컴포넌트 언마운트 시 이벤트 리스너 제거
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [editorRef]);

  return (
   <></>
  );
};



export default TextEditor;
