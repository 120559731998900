import React, { useState, lazy, Suspense, useCallback, useMemo } from 'react';
import SsembleSpinner from './../../ssembleSpinner';
import { instagram_icon, tiktok_icon, youtube_icon } from './../../../assets/social_icons';
const YouTubeForm = lazy( () =>
	import( './YouTubeForm' )
);
const InstagramForm = lazy( () =>
	import( './InstagramForm' )
);
const TiktokForm = lazy( () =>
	import( './TiktokForm' )
);

const ConnectedAccounts = React.memo(({ isLoggedIn, isTiktokLoggedIn, isInstaLoggedIn, onAddClick, video_url, videoTitle }) => {

  console.log("Connected Account isLoggedIn", isLoggedIn);
  console.log("Connected Account isTiktokLoggedIn", isTiktokLoggedIn);
  console.log("Connected Account isInstaLoggedIn", isInstaLoggedIn);
  console.log("Connected Account video_url", video_url);

  const [activeAccount, setActiveAccount] = useState('');

  const platforms = useMemo(() => [
    { id: 'youtube', icon: youtube_icon, name: 'YouTube Shorts', loggedIn: isLoggedIn },
    { id: 'tiktok', icon: tiktok_icon, name: 'TIKTOK', loggedIn: isTiktokLoggedIn },
    { id: 'instagram', icon: instagram_icon, name: 'Instagram Reels', loggedIn: isInstaLoggedIn },
  ], [isLoggedIn, isTiktokLoggedIn, isInstaLoggedIn]);

  const renderPlatformItem = useCallback((platform) => (
    <div
      key={platform.id}
      className={`plateformItem ${activeAccount === platform.id ? 'active' : ''}`}
      onClick={() => setActiveAccount(platform.id)}
    >
      <img src={platform.icon} alt="" />
      <p>{platform.name}</p>
    </div>
  ), [activeAccount]);

  const renderForm = () => {
    switch (activeAccount) {
      case 'youtube':
        return (
          <Suspense fallback={<SsembleSpinner />}>
            <YouTubeForm video_url={video_url} video_Title={videoTitle} />
          </Suspense>
        );
      case 'instagram':
        return (
          <Suspense fallback={<SsembleSpinner />}>
            <InstagramForm video_url={video_url} videoTitle={videoTitle} />
          </Suspense>
        );
      case 'tiktok':
        return (
          <Suspense fallback={<SsembleSpinner />}>
            <TiktokForm video_url={video_url} videoTitle={videoTitle} />
          </Suspense>
        );
      default:
        return <p className="selectSchText">Select an account to schedule your post.</p>;
    }
  };

  const hasConnectedAccounts = isLoggedIn && isTiktokLoggedIn && isInstaLoggedIn;

  return (
    <div className="connectedPlateforms-container">
      <div className="connectedPlateform connectedPlateforms-container-left">
        <div className="allPlateformText">All Plateforms</div>
        <div className="allplateformsWrapper">
          {platforms.map((platform) => platform.loggedIn && renderPlatformItem(platform))}
        </div>
        {!hasConnectedAccounts && (
          <div className="addPlateformWrapper">
            <button className="btn btn-outline-primary" onClick={onAddClick}>
              + Add Account
            </button>
          </div>
        )}
      </div>
      <div className="connectedPlateform connectedPlateforms-container-right">{renderForm()}</div>
    </div>
  );
});

export default ConnectedAccounts;