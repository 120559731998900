import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

const SchedulePost = () => {
  const [startDate, setStartDate] = useState(new Date());

  const isPastDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  const timeOptions = [
    { value: '00:00', label: '12 AM' },
    { value: '01:00', label: '1 AM' },
    // Add more time options here
  ];

  return (
    <div>
      <DatePicker
        selected={startDate}
        onChange={date => setStartDate(date)}
        showTimeSelect
        timeFormat="HH:mm"
        timeIntervals={60}
        timeCaption="time"
        dateFormat="MMMM d, yyyy h:mm aa"
        filterDate={isPastDate}
        minDate={new Date()}
      />
      <Select 
        options={timeOptions}
        placeholder="Select Time"
        // Handle onChange for Select here
      />
    </div>
  );
};

export default SchedulePost;