import { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { ClipLoader } from "react-spinners";
import ReactSlider from "react-slider";

import 'bootstrap/dist/css/bootstrap.min.css';

import VideoDragDropUploader from '../VideoDragDropUploader/index.js'
import { subscribeToEvent, validateURL } from "../../socket/video-socket";
import {
  getShorts,
  getUserRequests,
  deleteRequest,
  getUserCredits,
} from "../../api/shorts-api";

import croseImage from "../../assets/images/X.svg"

import { useDispatch } from "react-redux";
import { changeShortsMakerInputField } from './../../Redux/Actions/ToolActions.js'

import { ssSetUserCreditsUI } from './../../utils/helpers.js'

import UnderHeroWrapper from './../../components/ShortsMaker/TemplateParts/NonLoggedIn/UnderHeroWrapper.js';
import MultipleVideosThumbnail from './../../components/ShortsMaker/TemplateParts/NonLoggedIn/MultipleVideosThumbnail.js';
import SteamLineSection from './../../components/ShortsMaker/TemplateParts/NonLoggedIn/SteamLineSection.js';

// import DateTimePicker from './DateTimePicker';
import DateTimePickerWithTimezone from './DateTimePickerWithTimezone';
// import { DateTimePickerSS } from './../../components/DateTimePickerSS/index.js';
import SchedulePost from './SchedulePost';

const validateYoutubeURL = (url) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

const parseYoutubeURL = validateYoutubeURL;

const VideoInput = ({
  userId,
  userEmail,
  ytUrl,
  onURLChange,
  onNextStep,
  onClickVideo,
  ShortsMakerField
}) => {

  const [videoCompresorField, setVideoCompresorField] = useState(null);
  const [fileUrl, setfileUrl] = useState('');
  const [thumbnailUrl, setThmbnailUrl] = useState('');
  const [videoTitle, setvideoTitle] = useState('');
  const handleVideoCompresorData = (data) => {
    setVideoCompresorField(data);
  };

  const [touched, setTouched] = useState(false);
  const [isURLValid, setIsURLValid] = useState(false);
  const [isMusicVideo, setIsMusicVideo] = useState(false);
  const [youtubeURL, setYoutubeURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [enableBtn, setEnableBtn] = useState(false);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const [duration, setDuration] = useState(0);
  const [bestOption, setBestOption] = useState(null);
  const [title, setTitle] = useState(null);
  const inputRef = useRef(null);
  const [requests, setRequests] = useState([]);
  const [userCredits, setUserCredits] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [language, setLanguage] = useState("en");
  const [errorMessage, setErrorMessage] = useState('');
  const [hasError, setHasError] = useState(false);

  const [sliderValue, setSliderValue] = useState([0, duration]);


  const [languageOptions] = useState([
    // { label: "Auto", code: "auto", icon: null },
    { label: "English", code: "en", icon: '' },
    { label: "Hindi", code: "hi", icon: '' },
    { label: "Afrikaans", code: "af", icon: '' },
    { label: "Albanian", code: "sq", icon: '' },
    { label: "Amharic", code: "am", icon: '' },
    { label: "Arabic", code: "ar", icon: '' },
    { label: "Armenian", code: "hy", icon: '' },
    { label: "Assamese", code: "as", icon: '' },
    { label: "Azerbaijani", code: "az", icon: '' },
    { label: "Bashkir", code: "ba", icon: '' },
    { label: "Basque", code: "eu", icon: '' },
    { label: "Belarusian", code: "be", icon: '' },
    { label: "Bengali", code: "bn", icon: '' },
    { label: "Bosnian", code: "bs", icon: '' },
    { label: "Breton", code: "br", icon: '' },
    { label: "Bulgarian", code: "bg", icon: '' },
    { label: "Burmese", code: "my", icon: '' },
    { label: "Catalan", code: "ca", icon: '' },
    { label: "Chinese", code: "zh", icon: '' },
    { label: "Croatian", code: "hr", icon: '' },
    { label: "Czech", code: "cs", icon: '' },
    { label: "Danish", code: "da", icon: '' },
    { label: "Dutch", code: "nl", icon: '' },
    { label: "Estonian", code: "et", icon: '' },
    { label: "Faroese", code: "fo", icon: '' },
    { label: "Finnish", code: "fi", icon: '' },
    { label: "French", code: "fr", icon: '' },
    { label: "Galician", code: "gl", icon: '' },
    { label: "Georgian", code: "ka", icon: '' },
    { label: "German", code: "de", icon: '' },
    { label: "Greek", code: "el", icon: '' },
    { label: "Gujarati", code: "gu", icon: '' },
    { label: "Haitian Creole", code: "ht", icon: '' },
    { label: "Hausa", code: "ha", icon: '' },
    { label: "Hawaiian", code: "haw", icon: '' },
    { label: "Hebrew", code: "he", icon: '' },
    { label: "Hungarian", code: "hu", icon: '' },
    { label: "Icelandic", code: "is", icon: '' },
    { label: "Indonesian", code: "id", icon: '' },
    { label: "Italian", code: "it", icon: '' },
    { label: "Japanese", code: "ja", icon: '' },
    { label: "Javanese", code: "jw", icon: '' },
    { label: "Kannada", code: "kn", icon: '' },
    { label: "Kazakh", code: "kk", icon: '' },
    { label: "Khmer", code: "km", icon: '' },
    { label: "Korean", code: "ko", icon: '' },
    { label: "Lao", code: "lo", icon: '' },
    { label: "Latin", code: "la", icon: '' },
    { label: "Latvian", code: "lv", icon: '' },
    { label: "Lithuanian", code: "lt", icon: '' },
    { label: "Luxembourgish", code: "lb", icon: '' },
    { label: "Macedonian", code: "mk", icon: '' },
    { label: "Malagasy", code: "mg", icon: '' },
    { label: "Malay", code: "ms", icon: '' },
    { label: "Malayalam", code: "ml", icon: '' },
    { label: "Maltese", code: "mt", icon: '' },
    { label: "Maori", code: "mi", icon: '' },
    { label: "Marathi", code: "mr", icon: '' },
    { label: "Mongolian", code: "mn", icon: '' },
    { label: "Nepali", code: "ne", icon: '' },
    { label: "Norwegian", code: "no", icon: '' },
    { label: "Occitan", code: "oc", icon: '' },
    { label: "Pashto", code: "ps", icon: '' },
    { label: "Persian", code: "fa", icon: '' },
    { label: "Polish", code: "pl", icon: '' },
    { label: "Portuguese", code: "pt", icon: '' },
    { label: "Punjabi", code: "pa", icon: '' },
    { label: "Romanian", code: "ro", icon: '' },
    { label: "Russian", code: "ru", icon: '' },
    { label: "Sanskrit", code: "sa", icon: '' },
    { label: "Serbian", code: "sr", icon: '' },
    { label: "Shona", code: "sn", icon: '' },
    { label: "Sindhi", code: "sd", icon: '' },
    { label: "Sinhala", code: "si", icon: '' },
    { label: "Slovak", code: "sk", icon: '' },
    { label: "Slovenian", code: "sl", icon: '' },
    { label: "Somali", code: "so", icon: '' },
    { label: "Spanish", code: "es", icon: '' },
    { label: "Sundanese", code: "su", icon: '' },
    { label: "Swahili", code: "sw", icon: '' },
    { label: "Swedish", code: "sv", icon: '' },
    { label: "Tagalog", code: "tl", icon: '' },
    { label: "Tajik", code: "tg", icon: '' },
    { label: "Tamil", code: "ta", icon: '' },
    { label: "Tatar", code: "tt", icon: '' },
    { label: "Telugu", code: "te", icon: '' },
    { label: "Thai", code: "th", icon: '' },
    { label: "Tibetan", code: "bo", icon: '' },
    { label: "Turkish", code: "tr", icon: '' },
    { label: "Turkmen", code: "tk", icon: '' },
    { label: "Ukrainian", code: "uk", icon: '' },
    { label: "Urdu", code: "ur", icon: '' },
    { label: "Uzbek", code: "uz", icon: '' },
    { label: "Vietnamese", code: "vi", icon: '' },
    { label: "Welsh", code: "cy", icon: '' },
    { label: "Yiddish", code: "yi", icon: '' },
    { label: "Yoruba", code: "yo", icon: '' }
  ]);


  const [userAgreement, setUserAgreement] = useState(false);
  const handleUserAgreementChange = (e) => {
    setUserAgreement(e.target.checked);
  };

  const isFormValid = () => {
    return isURLValid && userAgreement;
  };


  useEffect(() => {
    console.log("videoCompresorField data on parent video input: ", videoCompresorField);
    if (videoCompresorField?.step === 3) {
      var videoTitleRes = videoCompresorField?.videoTitle || 'Sample Video ';
      var videoFileURL = videoCompresorField?.uploadFileData?.data?.videoFileURL;
      var thumbnailFileURL = videoCompresorField?.uploadFileData?.data?.thumbnailFileURL;
      var videoDuration = Math.round(videoCompresorField?.uploadFileData?.data?.duration);
      setfileUrl(videoFileURL);
      setThmbnailUrl(thumbnailFileURL);
      setTitle(videoTitleRes);
      setIsURLValid(true);
      setEnableBtn(true);
      setMax(videoDuration);
      setDuration(videoDuration);
    }
  }, [videoCompresorField])

  const [show, setShow] = useState(false);
  const [PopupShow, setPopupShow] = useState(false);
  const handleClose = () => setShow(false);
  const handlePopupClose = () => setPopupShow(false);
  const dispatch = useDispatch();
  useEffect(() => {
    GetPriceData();
  }, []);

  const GetPriceData = () => {
    dispatch(
      changeShortsMakerInputField({
        priceTableData: ShortsMakerField?.shortcodeSettings1?.priceTableData,
      })
    );
  };

  const setErrorState = (isError, message) => {
    setHasError(isError);
    setErrorMessage(message)
  };

  useEffect(() => {
    subscribeToEvent("validation_event", (data) => {
      setErrorState(false, '');
      setLoading(false);

      // disable live streaming videos
      if (data.info?.is_live) {
        setErrorState(data.info?.is_live, 'Live videos are not supported.');
        return;
      }

      if (data && data.info && data.info.categories) {
        // console.log("categories::", data.info.categories);
        const categories = data.info.categories;
        const targetCategories = ["music", "game", "gaming"];

        const includesTargetCategory = categories.some(category =>
          targetCategories.includes(category.toLowerCase())
        );

        // console.log("Target category included:", includesTargetCategory);

        if (includesTargetCategory) {
          // console.log("Either 'music' or 'game' category is present");
          setEnableBtn(false);
          setIsURLValid(false);
          setIsMusicVideo(true);
          setErrorState(true, 'Music/Game videos are not supported.');
          return;
        }
      }

      const isValidURL = data.isValidUrl;
      setEnableBtn(isValidURL);
      setIsURLValid(isValidURL);

      if (isValidURL) {
        const duration = data.duration;
        // const duration = 60;
        setMax(duration);
        setDuration(duration);
      }

      // formatValue
      if (data && data.format_options) {
        const bestOptionValue = selectBestResolution(data.format_options);
        if (bestOptionValue) {
          setBestOption(bestOptionValue); // Update the state with the selected option
          // console.log("bestOption", bestOptionValue);
        }
      }
      // title
      if (data && data.title) {
        setTitle(data.title);
      }

      // Check if language from data.info.language is in languageOptions
      // console.log("data above language set", data);
      if (data && data.info && data.info.language) {
        // console.log("sdsdsad");
        const matchingLanguage = languageOptions.find(
          (option) => option.code === data.info.language
        );

        if (matchingLanguage) {
          setLanguage(matchingLanguage.code); // Set the language state to the matching code
        }
      }
    });
  }, []);

  useEffect(() => {
    if (inputRef?.current && ytUrl) {
      inputRef.current.value = ytUrl;
      handleChange(null, ytUrl);
    }
  }, [ytUrl]);

  useEffect(() => {
    let isMounted = true;

    const fetchUserData = async () => {
      try {
        // const userRequests = await getUserRequests(userId);
        // const creditsResponse = await getUserCredits(userId);
        const creditsResponse = { "credits": 189598, "isPaidUser": true, "totalCredits": 216000 };

        if (isMounted) {
          // setRequests(userRequests);
          setUserCredits({
            credits: creditsResponse.credits,
            totalCredits: creditsResponse.totalCredits,
            usedCredits: creditsResponse.totalCredits - creditsResponse.credits,
            isPro: creditsResponse.isPaidUser,
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (userId) {
      fetchUserData();
    }

    return () => {
      isMounted = false;
    };
  }, [userId]);

  const selectBestResolution = (options) => {
    // Filter options with height 1080 or less
    const filteredOptions = options.filter((option) => option.height <= 1080);

    // Sort the options by height in descending order and return the first one
    if (filteredOptions.length > 0) {
      return filteredOptions.sort((a, b) => b.height - a.height)[0];
    } else {
      return null;
    }
  };


  useEffect(() => {
    setSliderValue([0, duration]);
  }, [youtubeURL, duration]);

  const handleChange = (ev, url) => {
    // console.log("here");
    setIsMusicVideo(false);
    setErrorState(false, '');
    if (!touched) setTouched(true);
    const value = ev ? ev.target.value : url;
    const isURLValid = validateYoutubeURL(value);
    // console.log("isURLValid", isURLValid);
    setEnableBtn(false);
    setIsURLValid(isURLValid);
    setYoutubeURL(value);

    if (isURLValid) {
      setLoading(true);
      validateURL(value);
      onURLChange(parseYoutubeURL(value));
    }
  };

  const handleSliderChange = (val) => {
    // Ensuring that start and end times are not negative
    const start = Math.max(val[0], 0);
    const end = Math.max(val[1], 0);
    setMin(start);
    setMax(end);

    setSliderValue(val);
  };

  async function handleGetShorts() {
    setEnableBtn(false);
    // console.log("handleGetShorts", bestOption);

    const parsedURL = validateYoutubeURL(youtubeURL);
    const requestedDuration = max - min; // Calculate the requested duration

    // check the latest user credits
    // console.log("checking user current credits left");
    // const UpdatedCreditsResponse = await getUserCredits(userId);
    const UpdatedCreditsResponse = { "credits": 189598, "isPaidUser": true, "totalCredits": 216000 };
    // console.log("After response of user credits", UpdatedCreditsResponse);
    setUserCredits({
      credits: UpdatedCreditsResponse.credits,
      totalCredits: UpdatedCreditsResponse.totalCredits,
      usedCredits: UpdatedCreditsResponse.totalCredits - UpdatedCreditsResponse.credits,
      isPro: UpdatedCreditsResponse.isPaidUser,
    });

    // console.log("state data for user credits", userCredits);

    // Check if requested duration is greater than remaining credits
    if (
      userCredits &&
      userCredits.isPro == false &&
      requestedDuration > userCredits.credits
    ) {
      setShow(true);
      return; // Stop further processing
    }

    if (userCredits && userCredits.isPro && requestedDuration > userCredits.credits) {
      setShowAlert(true); // Set the state to show the alert
      return; // Stop further processing
    }

    const data = {
      userId,
      userEmail,
      preferredLength: 'under30sec',
      fileUrl: fileUrl,
      start: min,
      end: max > 30 ? 30 : max,
      thumbnail: videoCompresorField?.step === 3 ? thumbnailUrl : `https://img.youtube.com/vi/${parsedURL}/sddefault.jpg`,
      title: title,
      language: language,
      captionLanguage: "",
      template: 'karaoke',
      templateId: '660b8531aedb346bbe26eb43',
      autoBroll: false,
      noClipping: false,
      noFaceDetection: true,
      freeUser: false,
      countryCode: 'US',
      hookTitle: false,
      memeHook: false,
      gameVideo: false,
    };
    if (bestOption) {
      data.formatOptionValue = bestOption.value;
    }

    console.log("Data before api call::::", data);
    // return; // Stop further processing

    // let requestId = await getShorts(data);
    let requestId = await getShorts(data);
    // console.log("handleGetShorts requestId", requestId);
    onNextStep(requestId);

    // update the user credits points on the wp side
    ssSetUserCreditsUI(userId);

  }

  const handleRequestDelete = async (deletedId) => {
    // console.log("handleDelete");
    await deleteRequest(deletedId);
    // console.log("Video successfully deleted");
    setRequests(requests.filter((request) => request.id !== deletedId));
  };

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    setLanguage(selectedLanguage);
    // console.log(`Selected language code: ${selectedLanguage}`);
  };


  const handleUploadReset = () => {
    setVideoCompresorField(null);
    setfileUrl('');
    setThmbnailUrl('');
    setTitle('');

    setIsURLValid(false);
    setEnableBtn(false);

    // const duration = 60;
    setMax(0);
    setDuration(0);
  }


  return (
    <div className="shortsSectionWrapper">
      <section className="heroSection white_bg mt-5">
        <div className="smallWrapper_sc">
          <div className="formFieldsGroup">
            <div className="formInputFields appendValidationRef">
              <div className="inputField">
                {/* <label className="form-label" htmlFor="inputPassword5">
                  YouTube video URL
                </label> */}


                {
                  videoCompresorField?.step === 3 && (
                    <section className="video-compressor-sec tools-sec afterUploadPreview">
                      <div className="container">
                        <div className="video-comp-con">
                          <p>{videoCompresorField?.fileName}</p>
                          <p><img src={croseImage} alt="Close" onClick={handleUploadReset} /></p>
                        </div>
                      </div>
                    </section>
                  )
                }

                {
                  fileUrl == '' && (
                    <>
                      <VideoDragDropUploader key={fileUrl} onSuccessUpload={handleVideoCompresorData} />
                      <p className="alert alert-danger">
                        Please ensure that the video you upload <b>contains spoken content</b>, as our system relies on the audio to generate transcriptions for the short videos.
                      </p>
                    </>

                  )
                }


                {/* <input
                  ref={inputRef}
                  className={classNames(
                    "youtubeChannel",
                    "form-control",
                    {
                      "tool-error": touched && !isURLValid,
                    }
                  )}
                  type="text"
                  disabled={loading}
                  placeholder="https://www.youtube.com/watch?v=BZk-iDBVnO0"
                  onChange={handleChange}
                /> */}

              </div>

              {
                hasError && errorMessage != '' && (
                  <div className="alert alert-danger centered mt-4">
                    <p>{errorMessage}</p>
                  </div>
                )
              }


              <div className="flex flex-col align-center mt-6">
                {loading && (
                  <div className="loading-sec loadingSpinnerShorts">
                    <ClipLoader />
                  </div>
                )}
                {isURLValid && enableBtn && (
                  <div className="loginProcessingTimeframe shortsChildContainer">
                    <div className="d-flex m-5">
                      <label className="">
                        Language
                      </label>
                      <select
                        id="language-select"
                        value={language}
                        onChange={handleLanguageChange}
                        className="form-select"
                      >
                        {languageOptions.map((option) => (
                          <option key={option.code} value={option.code}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    {
                      fileUrl && (
                        <video id="mp4uploadVideo" className="mp4uploadVideo" controls>
                          <source src={fileUrl} type="video/mp4" />
                        </video>
                      )
                    }


                    {/* Embed YouTube Player */}
                    {youtubeURL && (
                      <div
                        className="video-embed-container"
                        style={{
                          position: "relative",
                          paddingBottom: "56.25%" /* 16:9 */,
                          paddingTop: 25,
                          height: 0,
                        }}
                      >
                        <iframe
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                          src={`https://www.youtube.com/embed/${parseYoutubeURL(
                            youtubeURL
                          )}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded youtube"
                        />
                      </div>
                    )}

                    {duration > 60 && duration > 0 && (
                      <>
                        <label className="mt-5">
                          Processing Timeframe
                        </label>
                        <ReactSlider
                          className="horizontal-slider mb-10"
                          thumbClassName="slider-thumb"
                          trackClassName="slider-track"
                          min={0}
                          max={duration}
                          value={sliderValue} // Control value with state
                          renderThumb={(props, state) => (
                            <>
                              <div {...props}></div>
                              <div className="time-marker">
                                {new Date(state.valueNow * 1000).toISOString().slice(11, 19)}
                              </div>
                            </>
                          )}
                          minDistance={60}
                          pearling
                          onChange={handleSliderChange}
                        />

                      </>
                    )}
                  </div>
                )}
              </div>

              <div className="flex flex-col align-center mt-5 pt-3">
                <div className="form-check mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="userAgreement"
                    checked={userAgreement}
                    onChange={handleUserAgreementChange}
                  />
                  <label className="form-check-label" htmlFor="userAgreement">
                    I affirm that I have reviewed and accept the{" "}
                    <a href="/terms" target="_blank">
                      terms of service.
                    </a>
                    .
                  </label>
                </div>
              </div>

              <div className="submitField shortsMakerButtonWrap">
                <button
                  type="button"
                  disabled={!isFormValid()}
                  onClick={handleGetShorts}
                  className={
                    !isFormValid()
                      ? "shortsMakerButtonIsClicked disabled btn btn-primary"
                      : "shortsMakerButtonIsClicked btn btn-primary"
                  }
                >
                  Generate Short Clips
                </button>
              </div>

              <div>
                <p className="alert alert-info">Please note that generating your shorts may take <b>approximately 5-10 minutes</b>. Transcribing the video and exporting the final clip are time-consuming tasks to ensure the highest quality video output.</p>
              </div>

            </div>
          </div>



          <div className="loginGeneratorShortsContentBox">
            <div className="loginGeneratorContentBoxInner">
              <h6 className="generatorShorts">
                ShortCreator works best for TALKING videos.
              </h6>
              <div className="shortsMakersTalkingVideos dynamic">
                <ul className="worksBestTalkingVideos">
                  <li className="shortsCheckedMark">Video Podcasts</li>
                  <li className="shortsCheckedMark">Educational Videos</li>
                  <li className="shortsCheckedMark">Commentaries Videos</li>
                  <li className="shortsCheckedMark">Product Reviews</li>
                  <li className="shortsCheckedMark">Motivational Speeches</li>
                  <li className="shortsCroseMark">Vlog</li>
                  <li className="shortsCroseMark">Gaming</li>
                  <li className="shortsCroseMark">Music Videos</li>
                  <li className="shortsCroseMark">Live Videos</li>
                </ul>
              </div>
            </div>
          </div>


        </div>
      </section>

      <section className="underHeroSection grey_bg">
        <div className='shorts-container'>
          <UnderHeroWrapper
            ShortsMakerField={ShortsMakerField}
          />
          <MultipleVideosThumbnail
            ShortsMakerField={ShortsMakerField}
          />
        </div>
      </section>
      <SteamLineSection ShortsMakerField={ShortsMakerField} />


    </div>

  );
};

export default VideoInput;
