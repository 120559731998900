const PROGRESS = {
    FAILED: {
        value: 0,
        message: "Failed",
        stringValue: "failed",
    },
    CANCELLED: {
        value: 0,
        message: "Cancelled",
        stringValue: "cancelled",
    },
    WAITING_IN_QUEUE: {
        value: 0,
        message: "Waiting in queue...",
        stringValue: "waiting_in_queue",
    },
    FETCHING_VIDEO: {
        value: 10,
        message: "Fetching video...",
        stringValue: "fetching_video",
    },
    TRANSCRIBING_AUDIO: {
        value: 20,
        message: "Analyzing video...",
        stringValue: "transcribing_audio",
    },
    OPEN_AI_SUGGESTIONS: {
        value: 40,
        message: "Finding interesting segments",
        stringValue: "open_ai_suggestions",
    },
    FINALIZING_DATA: {
        value: 90,
        message: "Finalizing Data...",
        stringValue: "finalizing_data",
    },
    TASK_COMPLETE: {
        value: 100,
        message: "Task complete!",
        stringValue: "task_complete",
    },
};
const dcsfindProgressByStringValue = (stringValue) => {
    // Iterate over the keys of the PROGRESS object
    for (let key in PROGRESS) {
        if (PROGRESS.hasOwnProperty(key)) {
            // Check if the stringValue matches
            if (PROGRESS[key].stringValue === stringValue) {
                return PROGRESS[key];
            }
        }
    }
    // Return undefined or a suitable default value if no match is found
    return undefined;
};

const ssSetUserCreditsUI = async (userId) => {
    
    return true;
};

export { dcsfindProgressByStringValue, ssSetUserCreditsUI };
