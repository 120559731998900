const NoConnectedAccounts = ({ onAddClick }) => {
    return (
        <>
            <h3>Schedule post</h3>
            <p>Select the social accounts you would like to post from. You may select multiple accounts from the same social platforms.</p>
            <div className="mt-5 noConnectedAccountBody">
                <p>No social media accounts connected.</p>
                <p>Add one now to get started with the scheduler!</p>
                <button className='btn btn-outline-primary' onClick={onAddClick}>+ Add Account</button>
            </div>
        </>
    )
}

export default NoConnectedAccounts;