import { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
function TopInfoBar() {
  const [show, setShow] = useState(true);
  if (show) {
    return (
      <Alert variant="danger" onClose={() => setShow(false)} dismissible>
        <p>
          Auto-posting to Instagram is currently unavailable due to an ongoing Instagram outage. Our engineers are actively addressing this issue.
        </p>
      </Alert>
    );
  }
}

export default TopInfoBar;