const Score = ({ viral_score }) => {
  return (
    <div className="viral-score bg-purple rounded p-1">
      <div className="text-yellow text-lg text-center font-bold leading-none">{viral_score}</div>
      <div className="score-label text-white uppercase text-center text-[10px] font-medium">
        Score
      </div>
    </div>
  );
};

export default Score;
