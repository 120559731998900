import React, { useRef } from 'react';

const VideoPlayer = ({ src, hasControls=false }) => {
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused || videoRef.current.ended) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  return (
    <video
      controls={hasControls}
      ref={videoRef}
      src={src}
      controlsList="nodownload"
      onContextMenu={(e) => e.preventDefault()}
      onClick={togglePlayPause} // Add this line to handle the click event
      style={{ width: '100%' }}
    >
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;