import { CHANGE_VIDEO_COMPRESSOR_INPUT_FIELD } from '../Constant/ToolConstant';

const initialState = {
	videoCompresorField: {
		step: 1,
		VideoUrlInput: '',
		validUrlInput: true,
		uploadVideoLoader: false,
		uploadFileData: '',
		resolutionInput: {},
		frameRateInput: '',
		bitRateInput: '',
		EditVideoApiData: '',
		message: '',
		progressBar: '',
		final_data: '',
		uploadProgress: '',
		estimationCompressed: '',
	},
};
export const VideoCompresserReducer = ( state = initialState, action ) => {
	switch ( action.type ) {
		case CHANGE_VIDEO_COMPRESSOR_INPUT_FIELD:
			return {
				...state,
				...{
					videoCompresorField: {
						...state.videoCompresorField,
						...action.newState,
					},
				},
			};
		default:
			return state;
	}
};
