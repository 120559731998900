import { igRule1, igRule2, igRule3, igRule4, igRule5, connect_with_insta } from './../../../assets';
const InstagramTutorial = () => {

    return (
        <div className='instaram_tutorial_wrap'>
            <p className="instagram_body_heading">To be able to auto post to <img src={connect_with_insta} /> Instagram Reels:</p>
            <ol className="instgram_content_list">
                <li>
                    <h6> Switch your Instagram account to a Business or Creator profile</h6>
                    <p>(Instagram App &gt; Settings and privacy &gt; Creator tools)</p>
                    <div className='img-container'>
                        <img src={igRule1} alt='' />
                        <img src={igRule2} alt='' />
                        <img src={igRule3} alt='' />
                    </div>
                </li>
                <li>
                    <h6>Connect a Facebook Page to your Instagram profile</h6>
                    <p>(Instagram App &gt; Me tab &gt; Edit profile)</p>
                    <div className='img-container'>
                        <img src={igRule4} alt='' />
                        <img src={igRule5} alt='' />
                    </div>
                    <p className="img_bottom">Please note: Make sure that you are an admin of the Facebook Page.</p>
                    <p>When you click on Continue, you will be directed to Facebook, where you can grant us permissions to access your Instagram account.</p>
                </li>
            </ol>

        </div>
    );
}

export default InstagramTutorial;