import Score from "./Score";
import VideoPlayer from "../VideoPlayer";
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Short = ({ userId, requestUserEmail, item, index, ShortsMakerField, requestId }) => {
  const navigate = useNavigate();
  const thumbnail = item.thumbnail;
  const title = item.title;
  const reason = item.reason;
  const viral_score = item.viral_score;
  const clips = item.clips;
  const startTimestamp = item.startTimestamp;
  const endTimestamp = item.endTimestamp;

  const formatDuration = (startTimestamp, endTimestamp) => {
    const durationInSeconds = Math.floor(endTimestamp - startTimestamp);
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = durationInSeconds % 60;

    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return hours > 0
      ? `${hours}:${formattedMinutes}:${formattedSeconds}`
      : `${formattedMinutes}:${formattedSeconds}`;
  };


  // Retrieve the offset_x value from the first clip.
  let offset_x = 0; // Set a default value of 0.
  if (clips && clips[0]) {
    offset_x = clips[0].offset_x;
    // console.log('offset_x', offset_x);
  }
  const scaleFactor = 258 / 1920;
  const adjustedOffsetX = offset_x * scaleFactor;
  const imageStyle = {
    width: '100%', // Set to match the container width.
    height: '100%', // Set to match the container height.
    objectFit: 'cover', // Ensures the image maintains its aspect ratio and fills the container.
    objectPosition: `${adjustedOffsetX}px center` // Adjusts the horizontal position of the image.
  };

  return (
    <div className="SingleShortWrapper">
      <div className="short">
        <div className="short-left">
          <Score viral_score={viral_score} />
          <div
            className="shortClipTime"
            style={{
              textShadow: "1px 1px 2px black",
              paddingTop: "5px",
            }}
          >
            {formatDuration(startTimestamp, endTimestamp)}
          </div>

          {
            item?.video_url
              ?
              <div className="videoWrap">
                <VideoPlayer src={item?.video_url} />
              </div>
              :
              <div className="imageWrap" style={{ width: '145px', height: '258px', overflow: 'hidden' }}>
                <img src={thumbnail} alt={title} style={imageStyle} />
              </div>
          }
        </div>
        <div className="short-right">
          <h3>{title}</h3>
          <p>
            {reason}
          </p>
        </div>
      </div>
      <div className="button-group justify-content-around mt-3">
        <Button title='Upload to YouTube Channel/Instagram Reel/TikTok Feeds' variant="danger" onClick={() => navigate('/upload', { state: { ShortsMakerField: item } })}>
          Upload to Social Media
        </Button>
      </div>
    </div>
  );
};

export default Short;
