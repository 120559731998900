import React from 'react';
import SsembleSkeltonLoading from './components/SsembleSkeltonLoading';
import ShortsMaker from './components/ShortsMaker/ShortsMaker';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import uuid from 'react-uuid';
import './App.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AuthSection from './components/ShortsMaker/AuthSection';
import AuthSectionMultiSelect from './components/ShortsMaker/AuthSectionMultiSelect';
import AuthSectionMultiSelectDev from './components/ShortsMaker/AuthSectionMultiSelect-dev';
import AuthProcessing from './components/ShortsMaker/AuthProcessing';
import NotFound from './components/ShortsMaker/NotFound';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfServices from './components/TermsOfServices';
function App() {
  const shortcodeSettings = {};

  const searchParams = new URLSearchParams(window.location.search);
  const video_url123 = searchParams.has('video_url') ? searchParams.get('video_url') : null;


  return (
    
    <Routes>
    <Route path="/" element={<ShortsMaker shortcodeSettings={shortcodeSettings} />} />
    <Route path="/upload" element={<AuthSection video_url123={video_url123} />} />
    <Route path="/upload_multiple" element={<AuthSectionMultiSelect video_url123={video_url123} />} />
    <Route path="/upload_multiple_dev" element={<AuthSectionMultiSelectDev video_url123={video_url123} />} />
    <Route path="/privacy" element={<PrivacyPolicy />} />
    <Route path="/terms" element={<TermsOfServices />} />
    <Route path="/calllback" element={<AuthProcessing />} />
    
    <Route path="*" element={<NotFound />} />
  </Routes>
    
  );
}

export default App;
