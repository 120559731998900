// ShortsMakerFieldContext.js
import React from 'react';

const ShortsMakerFieldContext = React.createContext();

export default ShortsMakerFieldContext;
// You can add this in ShortsMakerFieldContext.js or a separate provider file

export const ShortsMakerFieldProvider = ({ children, ShortsMakerField }) => {
    return (
      <ShortsMakerFieldContext.Provider value={ShortsMakerField}>
        {children}
      </ShortsMakerFieldContext.Provider>
    );
  };
  