const generateCreateReq = (payload) => {
  const { file, totalDuration, clips, matchingWords } = payload;

  let lastEndTime = 0;
  const requests = [];

  for (let i = 0; i < clips.length; i++) {
    const { offset_x, end_time, start_time, face_found } = clips[i];
    let adjustedStartTime = start_time;
    let adjustedEndTime = end_time;

    if (start_time < lastEndTime) {
      adjustedStartTime = lastEndTime;
    }

    if (i === clips.length - 1) { // Check if it's the last clip
      const potentialEndTime = end_time + 0.5; // Add 0.5 seconds
      // console.log('trial for last clip stretch')
      if(potentialEndTime <= totalDuration){
        adjustedEndTime =  potentialEndTime; // Adjust end time if within total duration
        // console.log('--last clip stretch stretched--')
      }
    }

    const currentStartTime = lastEndTime; 
    lastEndTime = end_time; 

    const fillCanvas = face_found;
    let request = {
      type: "video",
      src: file,
      totalDuration: totalDuration,
      startTime: currentStartTime,
      timeRange: [adjustedStartTime, adjustedEndTime],
      isClip: true,
      fillCanvas: fillCanvas
    };
    
    // Add fillCanvas to the request only if fillCanvas is true
    if (face_found) {
      request.transform = {
        origin: {
          x: offset_x
        }
      };
    }
    
    requests.push(request);
  }

  // Process words in chunks of 5 for subtitles
  for (let i = 0; i < matchingWords.length; i += 5) {
    const wordChunk = matchingWords.slice(i, i + 5);
    const combinedText = wordChunk.map(w => w.word).join(' ');

    const subtitleStart = parseFloat(wordChunk[0].start);
    const subtitleEnd = parseFloat(wordChunk[wordChunk.length - 1].end);
    const subtitleDuration = parseFloat((subtitleEnd - subtitleStart).toFixed(3));

    let sentenceStartPosition = 0;
    const wordIntervals = wordChunk.map(word => {
      const wordStartPosition = combinedText.indexOf(word.word, sentenceStartPosition);
      const wordEndPosition = wordStartPosition + word.word.length;
      sentenceStartPosition = wordEndPosition;

      // Adjust timeRange to be relative to the start of the subtitle
      const relativeStart = parseFloat((word.start - subtitleStart).toFixed(3));
      const relativeEnd = parseFloat((word.end - subtitleStart).toFixed(3));

      return {
        text: word.word,
        position: {
          start: wordStartPosition,
          end: wordEndPosition,
          index: 0 // Assuming single line text
        },
        timeRange: {
          start: relativeStart*1000,
          end: relativeEnd*1000
        }
      };
    });

    // Only add the subtitle if the duration is greater than zero
    if (subtitleDuration > 0) {
      requests.push({
        type: 'subtitle',
        metadata: {
          text: combinedText,
          fontUrl: 'https://storage.googleapis.com/ssemble-static-assets/fonts/files/4a86fac9171387db1209869cf6290df0_Montserrat%20900_1640660086409.ttf',
          fontFamily: 'Montserrat-Black',
          fontSize: 60,
          fill: '#ffffff',
          stroke: '#000000ff',
          strokeWidth: 10,
          textBackgroundColor: "transparent",
          // styleType: "KARAOKE",
          // wordInterval: wordIntervals
        },
        startTime: subtitleStart,
        totalDuration: subtitleDuration,
        transform: {origin:{y:1500}},
      });
    }
  }

  return requests;
};

export default generateCreateReq;
