import { React, useState } from 'react';
import SsemblePricingCard from './ssemblePricingCard';
const SsemblePricingTabs = ( {ShortsMakerField} ) => {
	
	
	const [ activeTab, setActiveTab ] = useState( 1 );
	return (
		<>

			{/* {JSON.stringify(ShortsMakerField)} */}

			<div class="pricing-tab-content ssembleYtDownloaderPrice">
				
					<>
						<ul
							className="nav nav-tabs"
							id="ssemblemyTab"
							role="tablist"
						>
							{ ShortsMakerField?.priceTableData?.billing_cycles.map(
								( billing_cycle, index ) => (
									<li
										className="nav-item"
										role="presentation"
									>
										<button
											onClick={ () =>
												setActiveTab( index )
											}
											className={ `tab ${
												activeTab == index
													? 'active'
													: ''
											}` }
										>
											{
												billing_cycle.billing_cycle_title
											}
										</button>
										{ billing_cycle.has_off && (
											<div className="save-up">
												<span>
													{ billing_cycle.off_text }
												</span>
											</div>
										) }
									</li>
								)
							) }
						</ul>

						<div className="tab-content" id="ssemblemyTabContent">
							{ ShortsMakerField?.priceTableData?.billing_cycles.map(
								( billing_cycle, index ) => (
									<div
										className={ `tab-pane fade menu-content-head ${
											activeTab == index
												? 'active show'
												: ''
										}` }
									>
										<div className="tab-row">
											{ billing_cycle?.plans_levels.map(
												( plans_level, index ) => (
													<SsemblePricingCard
														ShortsMakerField={ ShortsMakerField }
														plans_level={ plans_level }
													/>
												)
											) }
										</div>
									</div>
								)
							) }
						</div>
					</>
			
			</div>
		</>
	);
};
export default SsemblePricingTabs;
