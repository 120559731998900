import Short from './Short';
import BackIcon from '../../assets/back.svg';
const frontendURL = process.env.REACT_APP_URL;
const ShortsList = ({ userId, request, onBack, onSelect, ShortsMakerField }) => {

  const requestId = request?._id;
  const requestUserEmail = request?.userEmail;

  const onBackHandle = () => {
    window.location.href = frontendURL;
  }


  return (
    <>
      <div className='shortsListTopNavWrap'>
        <div className='shortsListTopNav'>
          <button
            className="ss_back_btn"
            onClick={onBackHandle}
            style={{ paddingTop: '20px' }} >
            <img src={BackIcon} alt="back icon" className="inline" />
            <span className="text-xs text-grey-8">Back</span>
          </button>

         
        </div>
      </div>

      <div className='shortsMakerSmallWrap'>
        {/* New code as per chapters */}
        <div>
          {request?.shorts && request.shorts.length > 0 && (
            <div className="short-item">
              <Short 
                userId={userId} 
                requestUserEmail={requestUserEmail} 
                item={request.shorts[0]} 
                index="0" 
                ShortsMakerField={ShortsMakerField} 
                requestId={requestId} 
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
};

export default ShortsList;
