const NotFound = () => {
return (
    <>
    <div className="d-flex align-items-center justify-content-center vh-100 bg-seconary">
        <h1 className="display-1 fw-bold ">404</h1>
    </div>
    </>
    );
};
export default NotFound;
 